.loader_container {
  height: 100%;
  margin: 0 auto;
  width: 150px;
}
.loader_container::before {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.loader{
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.loader:before,
.loader:after{
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 50px;
  border-style: double;
  border-color: transparent;
  border-right-color: transparent;
  border-right-color: #4B0082;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  animation: spin 1s infinite;
  animation-delay: -0.25s;
}
.loader:after{
  left: 0;
  border-right-color: transparent;
  border-left-color: #4B0082;
  animation: spin 1s infinite;
  animation-delay: 0;
}
@keyframes spin{ 
  0%{ transform: rotate(360deg);} 
}